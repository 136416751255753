import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import "./App.scss";
import {
  VuiFlexContainer,
  VuiFlexItem,
  VuiHorizontalRule,
  VuiSpacer,
} from "./ui";
import { SearchResponse } from "./types/search";
import { sendSearchRequest } from "./services/sendSearchRequest";
import { Header } from "./components/Header";
import { Summary } from "./components/Summary";
import { SearchResults } from "./components/SearchResults";
import { LandingPage } from "./components/LandingPage";

let searchCount = 0;

export const App = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  const [searchValue, setSearchValue] = useState<string>();

  // Basic search
  const [isSearching, setIsSearching] = useState(false);
  const [searchError, setSearchError] = useState<any>();
  const [searchResponse, setSearchResponse] = useState<SearchResponse>();

  // Summarization
  const [isSummarizing, setIsSummarizing] = useState(false);
  const [summarizationError, setSummarizationError] = useState<any>();
  const [summarizationResponse, setSummarizationResponse] =
    useState<SearchResponse>();

  // Use the browser back and forward buttons to traverse history
  // of searches.
  useEffect(() => {
    const urlParams = new URLSearchParams(searchParams);
    const persistedSearchValue = decodeURIComponent(
      urlParams.get("query") ?? ""
    );

    setSearchValue(persistedSearchValue);
    onSearch(persistedSearchValue, false);
  }, [searchParams]);

  const onSearch = async (value?: string, isPersistable = true) => {
    const searchId = ++searchCount;

    if (value?.trim()) {
      // Persist to URL.
      if (isPersistable) {
        setSearchParams(
          new URLSearchParams(`?query=${encodeURIComponent(value)}`)
        );
      }
      setIsSearching(true);
      setIsSummarizing(true);

      try {
        const response = await sendSearchRequest({ query: value });
        // If we send multiple requests in rapid succession, we only want to
        // display the results of the most recent request.
        if (searchId === searchCount) {
          setIsSearching(false);
          setSearchError(undefined);
          setSearchResponse(response);
        }
      } catch (error) {
        setIsSearching(false);
        setSearchError(error);
        setSearchResponse(undefined);
      }

      try {
        const response = await sendSearchRequest({
          query: value,
          includeSummary: true,
        });
        const { summary, summaryErrorCode, summaryErrorMessage } = response;

        // If we send multiple requests in rapid succession, we only want to
        // display the results of the most recent request.
        console.log(searchId, searchCount);
        if (searchId === searchCount) {
          setIsSummarizing(false);
          setSummarizationError(undefined);

          if (summaryErrorCode === 0) {
            setSummarizationResponse({
              summary,
            });
          } else {
            setSummarizationError({
              message: summaryErrorMessage,
            });
          }
        }
      } catch (error) {
        setIsSummarizing(false);
        setSummarizationError(error);
        setSummarizationResponse(undefined);
      }
    } else {
      // Persist to URL.
      if (isPersistable) setSearchParams(new URLSearchParams(""));

      setSearchResponse(undefined);
      setSummarizationResponse(undefined);
    }
  };

  const updateSearchValue = (value?: string) => {
    setSearchValue(value);
  };

  let content;

  if (
    !isSearching &&
    !searchError &&
    !searchResponse &&
    !isSummarizing &&
    !summarizationError &&
    !summarizationResponse
  ) {
    content = (
      <LandingPage onSearch={onSearch} updateSearchValue={updateSearchValue} />
    );
  } else {
    content = (
      <>
        <Summary
          isSummarizing={isSummarizing}
          summarizationError={summarizationError}
          summarizationResponse={summarizationResponse}
        />
        <VuiSpacer size="l" />
        <SearchResults
          isSearching={isSearching}
          searchError={searchError}
          searchResponse={searchResponse}
        />
      </>
    );
  }

  return (
    <VuiFlexContainer
      className="app"
      direction="column"
      alignItems="center"
      spacing="none"
    >
      <VuiFlexItem className="headerContainer">
        <Header
          searchValue={searchValue}
          setSearchValue={updateSearchValue}
          onSearch={onSearch}
        />
      </VuiFlexItem>

      <VuiHorizontalRule />

      <VuiFlexItem grow={1} className="content">
        <>
          {prompt}
          {content}
        </>
      </VuiFlexItem>
    </VuiFlexContainer>
  );
};
