import { SearchResponseDoc } from "../types/search";
import { VuiLink, VuiTitle, VuiText, VuiTextColor, VuiSpacer } from "../ui";
import "./SearchResult.scss";

type Props = {
  doc: SearchResponseDoc;
  position: number;
};

const titleCase = (value: string) => {
  return value
    .toLowerCase()
    .split(" ")
    .map((word: string) => {
      return word.replace(word[0], word[0].toUpperCase());
    })
    .join(" ");
};

export const SearchResult = ({ doc, position }: Props) => {
  const { authority, siteCategory, title, url, date, sections } = doc;

  const dateObject = new Date(0);
  dateObject.setUTCSeconds(Number(date));
  const humanizedDate = dateObject.toLocaleDateString("en-us", {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  return (
    <div className="searchResult">
      <div className="searchResult__position">{position + 1}</div>

      <VuiTitle size="m">
        {url ? (
          <VuiLink href={url} target="_blank">
            {title}
          </VuiLink>
        ) : (
          <div>{title}</div>
        )}
      </VuiTitle>

      <VuiSpacer size="xs" />

      <VuiText size="s">
        <p>
          <VuiTextColor color="subdued">
            {authority} › {titleCase(siteCategory)}
          </VuiTextColor>
        </p>
      </VuiText>

      <VuiSpacer size="s" />

      <VuiText size="s">
        <p>
          <VuiTextColor color="subdued">{humanizedDate} &#8212;</VuiTextColor>{" "}
          {sections[0].pre} <strong>{sections[0].text}</strong>{" "}
          {sections[0].post}
        </p>
      </VuiText>
    </div>
  );
};
