import { BiMessageCheck } from "react-icons/bi";
import { SearchResponse } from "../types/search";
import {
  VuiCallout,
  VuiFlexContainer,
  VuiFlexItem,
  VuiIcon,
  VuiSpacer,
  VuiSpinner,
  VuiTextColor,
  VuiText,
  VuiTitle,
} from "../ui";
import { SearchResult } from "./SearchResult";

type Props = {
  isSearching: boolean;
  searchError?: any;
  searchResponse?: SearchResponse;
};

export const SearchResults = ({
  isSearching,
  searchError,
  searchResponse,
}: Props) => {
  if (isSearching) {
    // Loading indicator.
    return (
      <VuiFlexContainer
        className="loadingIndicator"
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <VuiSpinner size="xxxl" />

        <VuiSpacer size="m" />

        <VuiTitle size="l" align="center">
          <h2>Searching&hellip;</h2>
        </VuiTitle>
      </VuiFlexContainer>
    );
  }

  if (searchError) {
    // Log for diagnostics.
    console.error(searchError);
    return (
      <VuiCallout
        title="There was an error while searching"
        color="danger"
        headingElement="h2"
      >
        {searchError.message && (
          <VuiText>
            <p>
              <VuiTextColor color="danger">{searchError.message}</VuiTextColor>
            </p>
          </VuiText>
        )}
      </VuiCallout>
    );
  }

  return (
    <>
      <VuiFlexContainer spacing="xs" alignItems="center">
        <VuiFlexItem>
          <VuiIcon iconElement={BiMessageCheck} size={20} color="success" />
        </VuiFlexItem>
        <VuiFlexItem>
          <VuiText size="s">
            <p>
              <VuiTextColor color="success">
                {searchResponse?.docs?.length} results
              </VuiTextColor>
            </p>
          </VuiText>
        </VuiFlexItem>
      </VuiFlexContainer>

      <VuiSpacer size="l" />

      {searchResponse?.docs?.map((doc, i) => (
        <SearchResult key={doc.title} doc={doc} position={i} />
      ))}
    </>
  );
};
