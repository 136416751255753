import { BiError } from "react-icons/bi";
import { SearchResponse } from "../types/search";
import {
  VuiFlexContainer,
  VuiIcon,
  VuiPrompt,
  VuiSpacer,
  VuiSpinner,
  VuiTextColor,
  VuiText,
  VuiTitle,
} from "../ui";

type Props = {
  isSummarizing: boolean;
  summarizationError?: any;
  summarizationResponse?: SearchResponse;
};

export const Summary = ({
  isSummarizing,
  summarizationError,
  summarizationResponse,
}: Props) => {
  if (isSummarizing) {
    // Loading indicator.
    return (
      <VuiPrompt padding="xxl" isSpeechBubble>
        <VuiFlexContainer
          className="loadingIndicator"
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <VuiSpinner size="xl" />

          <VuiSpacer size="s" />

          <VuiTitle size="xs" align="center">
            <h2>Generating summary&hellip;</h2>
          </VuiTitle>
        </VuiFlexContainer>
      </VuiPrompt>
    );
  }

  if (summarizationError) {
    // Log for diagnostics.
    console.error(summarizationError);
    return (
      <VuiPrompt color="danger" padding="xxl">
        <VuiFlexContainer
          className="loadingIndicator"
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <VuiIcon iconElement={BiError} color="danger" size={40} />

          <VuiSpacer size="s" />

          <VuiTitle size="xs" align="center">
            <VuiTextColor color="danger">
              <h2>There was an error while summarizing</h2>
            </VuiTextColor>
          </VuiTitle>

          {summarizationError.message && (
            <>
              <VuiSpacer size="s" />
              <VuiText>
                <p>
                  <VuiTextColor color="danger">
                    {summarizationError.message}
                  </VuiTextColor>
                </p>
              </VuiText>
            </>
          )}
        </VuiFlexContainer>
      </VuiPrompt>
    );
  }

  return (
    <VuiPrompt isSpeechBubble>
      <VuiFlexContainer
        className="loadingIndicator"
        justifyContent="center"
        alignItems="center"
      >
        <VuiText size="l">
          <p>{summarizationResponse?.summary}</p>
        </VuiText>
      </VuiFlexContainer>
    </VuiPrompt>
  );
};
