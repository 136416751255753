type Config = {
  query?: string;
  includeSummary?: boolean;
};

export const sendSearchRequest = async ({ query, includeSummary }: Config) => {
  // @ts-expect-error - grecaptcha is a global variable.
  const recaptchaToken = await grecaptcha.execute(
    "6LdpCZYaAAAAAMAPK6OraZh_KUkYIkVXB_LAA2mb",
    { action: "homepage" }
  );

  const details: {
    q?: string;
    n: number;
    r: string;
    t: number;
    g?: boolean;
  } = {
    q: query,
    // Summary is only based on the first 5 results.
    n: includeSummary ? 5 : 25,
    r: recaptchaToken,
    t: 0,
    g: includeSummary,
  } as const;

  const values: string[] = [];
  for (const property in details) {
    const key = property as keyof typeof details;
    if (details[key] !== undefined) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[key]!.toString());
      values.push(encodedKey + "=" + encodedValue);
    }
  }
  const body = values.join("&");

  // Default options are marked with *
  const response = await fetch("/query", {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "no-cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      // "Content-Type": "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body, // body data type must match "Content-Type" header
  });
  const data = await response.json(); // parses JSON response into native JavaScript objects
  return data;
};
